<template>
  <b-form @submit.prevent="$emit('processAlarmas', alarmas)">
    <!-- Alarmas Info: Input Fields -->
    <b-form>
      <b-row class="mt-3">

        <!-- Field: Rojo -->
        <b-col
          cols="12"
          md="4"
        >
          <span
            class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer"
          />
          <span>Roja</span>
          <b-form-group
            label-for="tramoDesdeRojo"
          >
            <b-form-input
              v-if="permisosAlarma"
              id="tramoDesdeRojo"
              v-model="tramoDesdeRojo"
              :state="!$v.alarmas.tramoDesdeRojo.$invalid"
              placeholder="Introduce la alarma roja"
              @input="$v.alarmas.$touch"
              @blur="soloNumerosTramo"
              @keyup="tramoDesdeRojo = soloNumeros(tramoDesdeRojo)"
              :disabled="!permisosAlarma"
            />
            <b-form-input
              v-else
              id="tramoDesdeRojo"
              v-model="tramoDesdeRojo"
              :disabled="!permisosAlarma"
            />
            <b-form-invalid-feedback
              v-if="$v.alarmas.$dirty"
              id="alarmaRojoInfo"
            >
              El campo rojo
              <span v-if="!$v.alarmas.tramoDesdeRojo.required"> es requerido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Amarillo -->
        <b-col
          cols="12"
          md="4"
        >
          <span
            class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
          />
          <span>Amarilla</span>
          <b-form-group
            label-for="tramoDesdeAmarillo"
          >
            <b-form-input
              v-if="permisosAlarma"
              id="tramoDesdeAmarillo"
              v-model="tramoDesdeAmarillo"
              :state="!$v.alarmas.tramoDesdeAmarillo.$invalid"
              @input="$v.alarmas.$touch"
              placeholder="Introduce la alarma amarilla"
              @blur="soloNumerosTramo"
              @keyup="tramoDesdeAmarillo = soloNumeros(tramoDesdeAmarillo)"
              :disabled="!permisosAlarma"
            />
            <b-form-input
              v-else
              id="tramoDesdeAmarillo"
              v-model="tramoDesdeAmarillo"
              :disabled="!permisosAlarma"
            />
            <b-form-invalid-feedback
              v-if="$v.alarmas.$dirty"
              id="alarmaAmarilloInfo"
            >
              El campo amarillo
              <span v-if="!$v.alarmas.tramoDesdeAmarillo.required"> es requerido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Verde -->
        <b-col
          cols="12"
          md="4"
        >
          <span
            class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer"
          />
          <span>Verde</span>
          <b-form-group
            label-for="tramoDesdeVerde"
          >
            <b-form-input
              v-if="permisosAlarma"
              id="tramoDesdeVerde"
              v-model="tramoDesdeVerde"
              :state="!$v.alarmas.tramoDesdeVerde.$invalid"
              placeholder="Introduce la alarma verde"
              @input="$v.alarmas.$touch"
              @blur="soloNumerosTramo"
              @keyup="tramoDesdeVerde = soloNumeros(tramoDesdeVerde)"
              :disabled="!permisosAlarma"
            />
            <b-form-input
              v-else
              id="tramoDesdeVerde"
              v-model="tramoDesdeVerde"
              :disabled="!permisosAlarma"
            />
            <b-form-invalid-feedback
              v-if="$v.alarmas.$dirty"
              id="alarmaVerdeInfo"
            >
              El campo verde
              <span v-if="!$v.alarmas.tramoDesdeVerde.required"> es requerido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <btnSubmit
      v-if="permisosAlarma"
      :btnDisabled="$v.alarmas.$invalid"
      :btnText="alarmasSubmit"
    />

  </b-form>
</template>

<script>
import {
  BRow, BForm, BCol, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import {
  required,
} from 'vuelidate/lib/validators'
// import { soloNumerosTramo } from '@/mixins/forms'
import { soloNumerosTramo, formatos } from '@/mixins/forms'

const btnSubmit = () => import('@/layouts/components/Recycled/Form/btnSubmit.vue')

export default {
  components: {
    BRow,
    BForm,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    btnSubmit,
  },
  mixins: [validationMixin, soloNumerosTramo, formatos],
  computed: {
    permisosAlarma() {
      let result = false
      if (this.tipoUsuario === 'Admin'
        || this.tipoUsuario === 'Bodeguero') {
        result = true
      }
      return result
    },
  },
  props: {
    alarmas: {
      type: Object,
      required: true,
    },
    alarmasSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Alarma',
    },
    tipoUsuario: {
      type: String, // Texto del boton
      required: true,
    },
  },
  data() {
    return {
      tramoDesdeVerde: null,
      tramoDesdeAmarillo: null,
      tramoDesdeRojo: null,
    }
  },
  watch: {
    alarmas() {
      this.tramoDesdeVerde = this.alarmas.tramoDesdeVerde
      this.tramoDesdeAmarillo = this.alarmas.tramoDesdeAmarillo
      this.tramoDesdeRojo = this.alarmas.tramoDesdeRojo
    },
    tramoDesdeRojo() {
      this.alarmas.tramoDesdeRojo = this.tramoDesdeRojo
    },
    tramoDesdeAmarillo() {
      this.alarmas.tramoDesdeAmarillo = this.tramoDesdeAmarillo
    },
    tramoDesdeVerde() {
      this.alarmas.tramoDesdeVerde = this.tramoDesdeVerde
    },

  },
  validations() {
    return {
      alarmas: {
        tramoDesdeVerde: {
          required,
        },
        tramoDesdeAmarillo: {
          required,
        },
        tramoDesdeRojo: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
